$blue: #3f5b77;
$orange: #f5855d;
$gray: #606060;
$light: #eaeaea;
$lightpurple: #a98ec4;
$lightgreen: #80d37c;

$warning: $orange;
$success: darken($lightgreen, 25%);
$danger: darken($lightpurple, 25%);

$font-family-sans-serif: 'Roboto', sans-serif;
$font-weight-bolder: 900;
$fa-font-path: '../fonts';

// Typography
$body-color: $gray;
$lead-font-size: 2.375rem;
$h2-font-size: 2.375rem;
$paragraph-margin-bottom: 3.5rem;

// Navs
$nav-link-font-size: 1.25rem;

// Navbar
$navbar-nav-link-padding-x: 2rem;
$navbar-brand-font-size: 1.39375rem;


$navbar-light-brand-color:                $gray;
$navbar-light-brand-hover-color:          $gray;
$navbar-dark-brand-color:                #fff;
$navbar-dark-brand-hover-color:          #fff;

$navbar-light-color:               $gray;
$navbar-dark-active-color:			$warning;
$navbar-light-hover-color:          darken($gray, 10%);
$navbar-dark-color: #fff;
$navbar-light-active-color:			$warning;
$navbar-dark-hover-color: rgba(#fff, .85);

// Buttons
$btn-border-radius: 100px;
$btn-padding-y: 1rem;
$btn-padding-x: 2.5rem;
$btn-font-size: 1.375rem;

// Badges

$badge-font-size:                   1.625rem;
$badge-font-weight:                 500;
$badge-color: lighten($gray, 5%);
$badge-padding-y:                   0.5625rem;
$badge-padding-x:                   1.625rem;
$badge-border-radius:               0;

// Forms
$input-border-radius-lg: 0;
$input-bg: #f7f7f7;
$form-label-font-size: 1.875rem;


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;900&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
//@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';

h2 {
	font-weight: 900;
}
h3 {
	font-weight: 900;
}
p {
	font-size: 1.875rem;
}

.btn {
	box-shadow: 0 3px 6px rgba(#000, 0.20);
	text-transform: uppercase;
	font-weight: 900;
	transition: background-color .25s ease-in-out, transform .25s;
	&:hover {
		transform: scale(1.1);
	}
}

/*cover-xxl.jpg
cover-sm.jpg
cover-md.jpg
cover-lg.jpg*/

.cover {
	background: url(../images/cover-sm.jpg) 50% 50% no-repeat;
	background-size: cover;
	height: 100vh;
	@include media-breakpoint-up(sm) { background-image: url(../images/cover-md.jpg); }
	@include media-breakpoint-up(lg) { background-image: url(../images/cover-lg.jpg); }
	@include media-breakpoint-up(xxl) { background-image: url(../images/cover-xxl.jpg); }
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { background-image: url(../images/cover-md.jpg); }
	@media (min-width: map_get($grid-breakpoints, sm)), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { background-image: url(../images/cover-lg.jpg); }
	@media (min-width: map_get($grid-breakpoints, md)), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { background-image: url(../images/cover-xxl.jpg); }
}

/*p.lead {
	line-height: 1.5;
}*/

nav {
	transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out;
	&.navbar-light {
		box-shadow: 0 1px 5px rgba(#000, 0.15);
	}
	h1 {
		font-size: 1.39375rem;;
		line-height: 1.75rem;
		span {
			font-size: 1.75rem;
		}
	}
}
.anchor {
	//padding-top: 80px;
	//margin-top: -80px;
	scroll-margin-top: 70px;
}
/*.section {

	padding-top: calc(3rem + 80px) !important;
	margin-top: -80px;
}*/
/*.section::before {
  content: '';
  display: block;
  height:      175px;
  margin-top: -175px;
  visibility: hidden;
}*/

.form-control {
	border-bottom-width: 4px;
	border-bottom-color: $primary;
}

.about-img {
	width: 200px;
	height: 200px;
	border: 4px solid #fff;
	box-shadow: 1px 1px 3px rgba(#000, 0.20);
	@include media-breakpoint-up(xl) { width: 300px; height: 300px; }
}

.fa-code { color: lighten($blue, 25%); }
.fa-database { color: $lightpurple; }
.fa-pencil-ruler { color: lighten($orange, 10%); }
.fa-code-branch { color: $lightgreen; }

.skill-icon {
	max-width: 4.5rem;

	path {
    fill: transparent;
    stroke-width: 20px;
    stroke-linecap: round;
    stroke-miterlimit:10;
    transition: stroke-dashoffset 1s, stroke-width 1s 1s, fill 1s 1s;
  }
  &.skill-icon-frontend path {
  	stroke: $blue;
    stroke-dasharray: 1200px;
    stroke-dashoffset: 1200px;
  }
  &.skill-icon-backend path {
  	stroke: $lightpurple;
    stroke-dasharray: 1200px;
    stroke-dashoffset: 1200px;
  }
  &.skill-icon-design path {
  	stroke: lighten($orange, 10%);
    stroke-dasharray: 1250px;
    stroke-dashoffset: 1250px;
  }
  &.skill-icon-process path {
  	stroke: $lightgreen;
    stroke-dasharray: 2300px;
    stroke-dashoffset: 2300px;
  }
  &.animated {
    path {
      stroke-dashoffset: 0;
      stroke-width: 0;
      fill: red;
    }
  }
  &.skill-icon-frontend.animated path { fill: $blue; }
  &.skill-icon-backend.animated path { fill: $lightpurple; }
  &.skill-icon-design.animated path { fill: lighten($orange, 10%); }
  &.skill-icon-process.animated path { fill: $lightgreen; }
}

.badge {
	opacity: 0;
	transform: translateX(30px);
	transition: opacity 1s, transform 1s;
}
.badge.animated {
	opacity: 1;
	transform: translateX(0);
}

.client-link {
	.client-img {
		z-index: 20;
		transition: opacity .25s ease-in-out;
	}
	.client-img-hover {
		top: 0;
		left: 0;
	}
	&:hover {
		.client-img {
			opacity: 0;
		}
	}
}

footer {
	p {
		font-size: 1.25rem;
	}
	a {
		color: #fff;
		&:hover {
			color: $navbar-dark-hover-color;
		}
	}
	.social-icon-link {
		.social-icon {
			fill:#fff;
			transition: fill .25s, stroke-width .25s;
		  stroke: #fff;
		  stroke-linecap: round;
		  stroke-miterlimit:10;
		}
	  .social-icon-circle{
	  	fill:none;
	  	stroke:#F5855D;
	  	stroke-width:30;
	  	stroke-linecap:round;
	  	stroke-miterlimit:10;
	  	transform: scale(0);
		  transform-origin: 50% 50%;
		  transition: stroke-dashoffset .25s, transform .25s;
	  }
	  &:hover {
	  	.social-icon {
		    fill: #F5855D;
		    stroke-width: 20;
		  }
		  .social-icon-circle {
		    stroke-dashoffset: 0;
		    transform: scale(100%);
		  }
	  }
	}
}

.case-study {
	.hero {
		width: 100%;
		height: 300px;
		background: linear-gradient(135deg, #1E1E1E 10%, rgba(89, 89, 89, 0) 100%), url(/assets/images/cover-case-study-2.jpg) 50% 50% no-repeat;
		background-size: cover;
	}
	p, ul {
		font-weight: normal;
		font-size: 1.25rem;
		margin-bottom: 1.5rem;
	}
	p.lead {
		font-size: 2.375rem;
	}
	.starting-section {
		.screen-container {
			@include media-breakpoint-down(sm) { box-shadow: 0 3px 5px rgba(0,0,0,0.15); }
		}
		img {
			transform: translateY(400px);
			transition: transform 1.5s;
			&.animated {
				transform: translateY(50px);
			}
		}
	}
	.process-block {
		position: relative;
		background: linear-gradient(60deg, rgba(245,123,93,1) 0%, rgba(245,154,93,1) 100%);
		&:after {
			content: "";
			border-left: 4px dotted #fff;
			height: 1rem;
			position: absolute;
			left: 50%;
			bottom: -1.25rem;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	.mobile {
		background: rgb(175,70,213);
		background: linear-gradient(60deg, rgba(175,70,213,1) 0%, rgba(107,71,241,1) 100%);
		@include media-breakpoint-up(md) { overflow: hidden; }
		.phone-col {
			@include media-breakpoint-down(sm) {
				box-shadow: 0 3px 5px rgba(0,0,0,0.15);
			}
			@include media-breakpoint-down(md) {
				overflow: hidden;
				min-height: 450px;
			}
			img {
				width: 244.5px;
				height: 500px;
				transform-origin: 50% 100%;
				transform: translateX(-300px) rotate(-15deg);
				transition: transform 1.5s;
				bottom: -100px;
				&.animated {
					transform: translateX(0) rotate(10deg);
				}
			}
		}
	}
}